@import '../theme-bootstrap';
@import '../../../../../all/themes/elc_base/scss/standalone/shared/animations';

// Skeleton Product Full
.skeleton-full {
  padding: 3px 0 0;
  width: 100%;
  @include breakpoint($medium-up) {
    padding: 10px 0 0;
  }
  @include breakpoint($landscape-up) {
    padding: 42px 0;
  }
  .elc-skeleton-region {
    &--left-column {
      @include breakpoint($medium-up) {
        width: 54%;
        flex: 1;
      }
      @include breakpoint($xlarge-up) {
        width: 68%;
        flex: 1;
      }
      .elc-skeleton-feature {
        padding-inline: 20px;
        @include breakpoint($medium-up) {
          padding-inline: 0;
        }
        &--desktopWrapper {
          display: none;
          @include breakpoint($medium-up) {
            width: 100%;
            display: block;
          }
        }
        &--carousel {
          padding-inline: 0;
          display: flex;
          flex-direction: row;
          margin: 36px auto 0;
          @include breakpoint($medium-up) {
            margin: 0 auto;
          }
          .main-carousel-image {
            aspect-ratio: 1;
            background-color: $color-bianca;
            display: flex;
            height: 100%;
            width: 100%;
            max-width: 100%;
            @include breakpoint($medium-up) {
              margin-inline: #{'min(2.5%, 20px)'} auto;
              max-width: calc(100% - #{'min(15%, 100px)'} - #{'min(10%, 80px)'});
            }
          }
          .elc-slider-view-wrapper {
            display: none;
            width: 100%;
            max-width: #{'min(15%, 100px)'};
            margin-block: -4px 0;
            :has(.slick-arrow) {
              margin-block: 16px;
            }
            @include breakpoint($medium-up) {
              display: flex;
            }
            .elc-product-images-wrapper {
              background-color: $color-bianca;
              margin-block: 5px;
              padding: 0;
              &:first-of-type {
                .elc-img {
                  border: 1px solid $color-charleston-green;
                }
              }
            }
            .slick-slider {
              position: relative;
              height: fit-content;
              .slick-track {
                display: flex;
                flex-direction: column;
              }
            }
            .elc-slider-arrow-wrapper {
              position: absolute;
              #{$rdirection}: unset;
              #{$ldirection}: 50%;
              margin-#{$ldirection}: -11px;
              transform: rotate(90deg);
              &.slick-prev {
                top: -20px;
              }
              &.slick-next {
                top: unset;
                bottom: -20px;
              }
            }
          }
        }
        .elc-skeleton-child--socialShare {
          @include shimmer(1.5s, ease-in-out);
          width: 20%;
          margin: 0 auto;
          height: 30px;
        }
      }
      .elc-skeleton-feature--mobileWrapper {
        @include breakpoint($medium-up) {
          display: none;
        }
        .elc-skeleton-reviews-text {
          display: none;
        }
        .elc-skeleton-child--displayName {
          margin: 20px 0 0;
          width: 82%;
          h1 {
            @include title--h1;
          }
        }
      }
    }
    &--right-column {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      @include breakpoint($medium-up) {
        width: 46%;
        max-width: 390px;
        display: unset;
      }
      @include breakpoint($xlarge-up) {
        width: 32%;
      }
      .elc-skeleton-feature--desktopWrapper {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
        .elc-skeleton-child--displayName {
          margin: 20px 0 0;
          h1 {
            @include title--h1;
          }
        }
      }
      .elc-skeleton-feature {
        padding-inline: 20px;
        @include breakpoint($medium-up) {
          padding-inline: 0;
        }
        &--displayName {
          order: 2;
          margin: 10px 0 13px;
          @include breakpoint($medium-up) {
            margin: unset;
            order: unset;
          }
          h1 {
            @include title--h1;
            margin: 10px 0 0;
          }
        }
        &--badge {
          margin-top: 33px;
          order: 1;
          @include breakpoint($medium-up) {
            height: 20px;
            width: 50%;
            margin-top: unset;
            order: unset;
          }
        }
        &--ratingAndReviews {
          height: 25px;
          margin-top: 8px;
          width: 100%;
          color: $color-black;
          font-size: 12px;
          font-weight: bold;
          text-decoration: underline;
          text-transform: capitalize;
          text-align: $rdirection;
          padding-#{$rdirection}: 10px;
          order: 5;
          @include breakpoint($medium-up) {
            order: unset;
            margin-top: 40px;
          }
          .elc-skeleton-reviews-text {
            display: inline-block;
            margin-#{$rdirection}: 100px;
          }
        }
        &--sizePicker {
          width: 100%;
          order: 6;
          @include breakpoint($medium-up) {
            margin-top: 26px;
            order: unset;
          }
          .elc-slider-view-wrapper {
            display: flex;
            justify-content: flex-start;
          }
          .elc-product-images-wrapper {
            display: none;
            @include breakpoint($medium-up) {
              display: block;
            }
          }
          .elc-size-picker-box-item-wrapper {
            display: flex;
          }
        }
        &--price {
          order: 3;
          @include shimmer(1.5s, ease-in-out);
          height: 30px;
          margin-top: 0;
          margin-inline: 20px;
          @include breakpoint($medium-up) {
            order: unset;
            margin-inline: 0;
          }
        }
        &--socialShare,
        &--favoriteButton,
        &--findInStoreButton,
        &--installmentsPrice {
          @include shimmer(1.5s, ease-in-out);
          margin-top: 20px;
          height: 30px;
          width: 48%;
          float: $ldirection;
          margin-inline: 20px calc(2% + 20px);
          @include breakpoint($medium-up) {
            margin-inline: 0 2%;
          }
        }
        &--findInStoreButton {
          order: 10;
          @include breakpoint($medium-up) {
            order: unset;
            width: 100%;
          }
        }
        &--engraving {
          @include shimmer(1.5s, ease-in-out);
          height: 42px;
          margin: 10px 20px 0;
          border: 1px solid $color-cream-stroke;
          order: 7;
          @include breakpoint($medium-up) {
            margin: 10px 0 0;
            order: unset;
            width: 100%;
          }
        }
        &--installmentsPrice {
          margin-inline: 20px;
          height: 50px;
          order: 9;
          @include breakpoint($medium-up) {
            margin-top: 20px;
            margin-inline: 0;
            order: unset;
            width: 100%;
          }
        }
        &--favoriteButton {
          float: $rdirection;
          margin-inline: 20px;
          order: 12;
          @include breakpoint($medium-up) {
            margin-inline: 2% 0;
            order: unset;
          }
        }
        &--addToBag {
          background: $color-black;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          color: $black;
          font-family: $font--lars;
          text-align: center;
          max-width: unset;
          border-radius: unset;
          height: 50px;
          margin: 20px 20px 0;
          order: 6;
          @include breakpoint($medium-up) {
            margin: 20px 0 0;
            order: unset;
          }
        }
        &--socialShare {
          display: block;
          order: 11;
          @include breakpoint($medium-up) {
            display: none;
            order: unset;
          }
        }
        &--overview {
          order: 4;
          margin-top: 36px;
          @include breakpoint($medium-up) {
            height: 80px;
            margin-top: 30px;
            width: 100%;
            order: unset;
          }
          .elc-body--2 {
            line-height: 2;
            overflow: hidden;
            height: 72px;
          }
        }
      }
    }
    &--info-content {
      width: 100%;
      padding: 0 20px;
      @include breakpoint($medium-up) {
        padding: 0 48px;
      }
      .elc-skeleton-feature {
        &--howDoesItWork,
        &--ingredients {
          @include shimmer(1.5s, ease-in-out);
          height: 118px;
          margin-top: 40px;
        }
      }
    }
  }
}
